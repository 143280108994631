import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createGlobalStyle } from 'styled-components';
import TopbarLanguage from '../topbar/TopbarLanguage';
import {
  FOOTER_NAV_GROUPS,
  FOOTER_NAV_SECONDARY,
  FOOTER_NAV_SOCIAL
} from './Footer.constants';
import {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterCopyright,
  StyledFooterLogoLang,
  StyledFooterMain,
  StyledFooterNav,
  StyledFooterNavCopy,
  StyledFooterNavItem,
  StyledFooterSecondary,
  StyledFooterSocial,
  StyledNavGroup,
  StyledNavGroupTitle
} from './Footer.styled';
import { FooterProps } from './Footer.types';

const GlobalStyle = createGlobalStyle<{ footerHeight: number }>`
  body {
    padding-bottom: ${(props) =>
      props.footerHeight ? props.footerHeight + 'px !important' : ''};
  }

  @media (max-height: ${(props) => props.footerHeight + 'px'}) {
    body {
      padding-bottom: 0 !important;
    }
    .main-footer {
      position: static !important;
    }
  }
`;

const Footer: React.FC<FooterProps> = ({
  offerFooter,
  hasScrollTop,
  isInCustomFooter
}) => {
  const [height, setHeight] = useState(0);
  const [year, setYear] = useState<number | null>(null);
  const footerRef = useRef<HTMLElement>(null);

  const { t } = useI18n('footer');
  const { locale } = useRouter();

  function footerHeight(): void {
    if (footerRef?.current) {
      setHeight(footerRef.current.clientHeight);
    }
  }

  useEffect(() => {
    footerHeight();

    const triggerEvent = isMobile ? 'orientationchange' : 'resize';

    window.addEventListener(triggerEvent, footerHeight);

    return () => {
      window.removeEventListener(triggerEvent, footerHeight);
    };
  }, []);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  const footerNav = FOOTER_NAV_GROUPS(t, locale);
  const footerNavSecondary = FOOTER_NAV_SECONDARY(t, locale);
  const footerNavSocial = FOOTER_NAV_SOCIAL();

  return (
    <>
      <GlobalStyle footerHeight={height} />
      <StyledFooter
        ref={isInCustomFooter ? undefined : footerRef}
        className={`${isInCustomFooter ? '' : 'main-footer'} ${
          offerFooter && !hasScrollTop ? 'not-scrollTop' : ''
        }`}
      >
        {offerFooter}
        <StyledFooterContainer>
          <StyledFooterMain>
            <StyledFooterLogoLang>
              <img
                src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_blanco.svg"
                alt="Manfred logo"
                width="132"
                height="24"
              />
              <TopbarLanguage />
            </StyledFooterLogoLang>
            <StyledFooterNav aria-label={t('nav.label')}>
              {footerNav.map((group, index) => (
                <StyledNavGroup key={index}>
                  <StyledNavGroupTitle>{group.title}</StyledNavGroupTitle>
                  <ul>
                    {group.navItems.map((navItem, index) => (
                      <React.Fragment key={index}>
                        <li>
                          <Link href={navItem.href} passHref>
                            <StyledFooterNavItem>
                              {navItem.title}
                            </StyledFooterNavItem>
                          </Link>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                  {group.action && <>{group.action}</>}
                </StyledNavGroup>
              ))}
            </StyledFooterNav>
          </StyledFooterMain>
          <StyledFooterNavCopy>
            <StyledFooterCopyright>
              &copy; {year} {t('nav.manfredTech')}
            </StyledFooterCopyright>
            <StyledFooterSecondary>
              <ul>
                {footerNavSecondary.map((navItem, index) => (
                  <li key={index}>
                    <Link href={navItem.href} passHref>
                      <StyledFooterNavItem>{navItem.title}</StyledFooterNavItem>
                    </Link>
                  </li>
                ))}
              </ul>
              <ul>
                {footerNavSocial.map((navItem, index) => (
                  <li key={index}>
                    <StyledFooterSocial
                      title={navItem.title}
                      href={navItem.href}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <FontAwesomeIcon icon={navItem.icon} />
                    </StyledFooterSocial>
                  </li>
                ))}
              </ul>
            </StyledFooterSecondary>
          </StyledFooterNavCopy>
        </StyledFooterContainer>
      </StyledFooter>
    </>
  );
};

export default Footer;

import { Dropdown, Stack } from '@asgard/ui-library';
import { useUser } from '@auth0/nextjs-auth0/client';
import { faBuilding, faUserAstronaut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonLink from 'components/shared/ButtonLink.styled';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { config } from 'utils/config';
import { StyledHeaderActions } from './TopbarActions.styled';
import TopbarLanguage from './TopbarLanguage';

const TopbarActions: React.FC = () => {
  const { t } = useI18n('topbar');
  const { locale } = useRouter();
  const { user, isLoading } = useUser();

  if (isLoading) return null;

  return (
    <>
      <StyledHeaderActions>
        {!user && (
          <Stack gap="m">
            <ButtonLink
              href={`${getAppUrl(
                APP_URLS.join,
                locale
              )}?locale=${locale}&source=unete-header`}
              sizes="sm"
              variant="brandTalent"
              aria-label={t('actions.signup')}
              className="signup"
              onClick={(e) =>
                ServiceEventTracking.trackExternalLandingCtaClicked(
                  e,
                  `${getAppUrl(
                    APP_URLS.join,
                    locale
                  )}?locale=${locale}&source=unete-header`,
                  'unete-header'
                )
              }
            >
              <FontAwesomeIcon icon={faUserAstronaut} />
              <span aria-hidden="true">{t('actions.signup')}</span>
            </ButtonLink>

            <div className="signin">
              <Dropdown
                label={t('actions.signin')}
                variant="ghost"
                schema="brand2"
                size="xs"
                position="right"
                options={[
                  {
                    asLink: true,
                    href: `${getAppUrl(
                      APP_URLS.login,
                      locale
                    )}?locale=${locale}`,
                    id: 0,
                    label: 'Talento',
                    icon: faUserAstronaut
                  },

                  {
                    asLink: true,
                    href: `${config.talentEngineClientUrl}/external-login`,
                    id: 1,
                    label: 'Empresas',
                    icon: faBuilding
                  }
                ]}
              />
            </div>
          </Stack>
        )}

        {user && (
          <Link href={getAppUrl(APP_URLS.portalV2Dashboard, locale)} passHref>
            <ButtonLink sizes="sm" variant="brandTalent">
              <FontAwesomeIcon icon={faUserAstronaut} />
              <span>{t('nav.privateArea')}</span>
            </ButtonLink>
          </Link>
        )}

        <TopbarLanguage />
      </StyledHeaderActions>
    </>
  );
};

export default TopbarActions;
